/* vars */

:root {
  --pureWhite: white;
  --gold: gold;
  --black: black;
}

body {
  background: var(--black);
}

/* content */

.main {
  height: 100vh;
  width: 100vw;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  padding-top: 3rem;
}

.hero .title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: clamp(64px, 10vw, 80px);
  font-family: 'BetterGrade', sans-serif;
}

.hero .subtitle {
  font-style: italic;
  font-weight: 300;
  font-family: "Work Sans", sans-serif;
  font-size: clamp(20px, 3vw, 25px);
  margin-top: 0.5rem;
}

.hero span {
    color: white;
    margin: auto 1rem;
    font-size: 2.25rem;
}

.hero .subtitle {
  font-style: italic;
  font-weight: 300;
  font-family: "Work Sans", sans-serif;
  font-size: clamp(20px, 3vw, 25px);
}

.hero .cliffhanger {
  font-family: "Work Sans", sans-serif;
  font-weight: 200 !important;
  color: var(--pureWhite);
  text-align: center;
  margin: 0;
  font-size: 1.25rem;
}

.divider {
  max-width: 25%;
  margin: 1rem auto;
}

.hero .title,
.subtitle {
  line-height: 2rem;
  margin: 0;
  text-align: center;
  color: var(--pureWhite);
}


.splash-image {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 1rem;
}

/* Media Querys */

@media only screen and (min-width: 425px) {
  .hero {
    height: 150px;
  }

  .hero .splash-image {
    margin: 0;
  }
}

@media only screen and (min-width: 615px) {
  .hero .title {
    flex-direction: row;
  }

  .hero .cliffhanger {
    font-size: 1.5rem;
  }
}


/* snakeline hover effect */

.hero .cliffhanger > a {
  color: var(--gold);
  display: inline-block;
  position: relative;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

.hero .cliffhanger > a:before {
  content: "";
  background: var(--gold);
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0;
  height: 1px;
  transition: all 0.3s ease-in-out;
}

.hero .cliffhanger > a:hover {
  background-position: 0;
}

.hero .cliffhanger > a:hover::before {
  width: 100%;
}